import React, { FC } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import "./Card.css"

type CardProps = {
    header_text: string,
    text: string,
    image: IGatsbyImageData,
    size?: number;
};

const Card: FC <CardProps> = ({header_text, text, image, size}) => {
    return (
        <div className='card_container' style={{ width: size }}>
              <div className='image_container'>
                  <GatsbyImage className='image' alt='' image={image}></GatsbyImage>
                  <div className='image_gradient_bottom'></div>
              </div>

              <div className='text_container' style={{backgroundColor: '#fff', height: '100%'}}>
                <p className='text_header'>{header_text}</p>
                <p className='text_content'>{text}</p>
              </div>

        </div>
    );
}

export default Card;